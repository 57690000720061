const sql = [
  {
    _id: '1',
    title: '1. Introduction to Relational Database Management Systems (RDBMS).',
    detail1: 'Definition and Characteristics.',
    detail2: ' Benefits of RDBMS.',
    detail3: 'Tables, Rows, and Columns.',
    detail4: 'Keys (Primary Key, Foreign Key, Unique Key).',
    detail5: 'Relationships (One-to-One, One-to-Many, Many-to-Many).',
    
    
  },
  {
    _id: '2',
    title: '2. SQL Basics',
    detail1: 'SQL Syntax and Structure.',
    detail2: 'Types of SQL Commands: DDL, DML, DCL, TCL.',
    detail3: 'Creating Tables in MySQL.',
    detail4: 'Defining Data Types and Constraints.',
    detail5: 'Altering and Dropping Tables.',
    
    
  },
  {
    _id: '3',
    title: '3. Operators in Java',
    detail1: 'Arithmetic Operators.',
    detail2: 'Relational Operators.',
    detail3: 'Logical Operators.',
    detail4: 'Assignment Operators.',
    detail5: 'Unary and Bitwise Operators.',
    
    
  },
  {
    _id: '4',
    title: '4. Inserting, Updating, Deleting, and Retrieving  Data',
    detail1: 'INSERT INTO statement.',
    detail2: 'UPDATE statement.',
    detail3: 'DELETE statement.',
    detail4: 'Retrieving Data with SELECT - Filtering Data using WHERE.',
    detail5: 'Sorting Data using ORDER BY.',
    
    
  }, 
  {
    _id: '5',
    title: '5. Advanced SQL Queries',
    detail1: 'Aggregate Functions: COUNT(), SUM(), AVG(), MIN(), MAX() .',
    detail2: 'GROUP BY and HAVING clauses.',
    detail3: 'Using aggregate functions for reports.',
    detail4: 'BETWEEN, IN, LIKE, IS NULL.',
    detail5: 'CASE WHEN statements for conditional queries.',
    
    
  }, 
  {
    _id: '6',
    title: '6. Joining Tables',
    detail1: 'Inner Join, Left Join, Right Join, Full Join.',
    detail2: 'Self Join and Cross Join.',
    detail3: 'Subqueries in SELECT, WHERE, and FROM clauses.',
    detail4: 'Set Operations: UNION, UNION ALL, INTERSECT, EXCEPT.',
    detail5: 'Practical examples using ClassicModel database.',
    
    
  },
  {
    _id: '7',
    title: '7. Database Design',
    detail1: 'Understanding Requirements and Entities.',
    detail2: 'Identifying Relationships between Entities.',
    detail3: 'Primary Key vs Foreign Key.',
    detail4: 'Unique Constraints.',
    detail5: 'Not Null and Check Constraints.',
    
    
  },
  {
    _id: '8',
    title: '8. Advanced SQL Features',
    detail1: 'Understanding Indexes.',
    detail2: 'Creating and Dropping Indexes.',
    detail3: 'Query Performance and Optimizing Queries.',
    detail4: 'Creating, Managing and Using Views for Simplifying Queries.',
    detail5: 'Creating Stored Procedures and Functions.',
    
    
  },
  {
    _id: '9',
    title: '9. Data Security and Backup/Recovery',
    detail1: 'Creating Users and Roles in MySQL.',
    detail2: 'Granting and Revoking Permissions.',
    detail3: 'Securing Data with Roles and Privileges.',
    detail4: 'Ensuring Data Quality through Constraints.',
    detail5: 'Cascading Updates and Deletes.',
    
    
  },
  {
    _id: '10',
    title: '10. Real-World Use Cases and Projects',
    detail1: 'Building Reports with SQL Queries.',
    detail2: 'Working with Aggregate Functions to Analyze Data.',
    detail3: 'Writing complex SQL queries to track orders, payments, and customer data.',
    detail4: 'Generating HR reports to analyze employee performance, salaries, and office locations.',
    detail5: 'SQL queries to analyze product sales, stock levels, and inventory.',
    
    
  },
]

export default sql
