const powerbi = [
  {
    _id: "1",
    title: "1. Introduction to Power BI",
    detail1: "Overview of Power BI and its capabilities",
    detail2: "Why Power BI?  Benefits and Key Features",
    detail3: "Understanding Power BI Components: Power BI Desktop, Power BI Service, and Power BI Mobile.",
    detail4:
      "Power BI vs Other BI Tools.",
    detail5:
      "Power BI account creation and licensing options.",
  },
  {
    _id: "2",
    title: "2. Getting Started with Power BI Desktop.",
    detail1: "Power BI Desktop layout: Report, Data, and Model views",
    detail2: "The Ribbon and Toolbar: Key features.",
    detail3: "Overview of available data sources: Excel, SQL Server, Web, API, and more.",
    detail4: "Importing data from various sources.",
    detail5: "Using Power Query Editor for data cleaning and transformation.",
  },
  {
    _id: "3",
    title: "3. Conditional Statements",
    detail1: "if, elif, else statements",
    detail2:
      "Nested Conditions",
    detail3: "for loop",
    detail4:
      "while loop",
    detail5:
      "break, continue, and pass",
  },
  {
    _id: "3",
    title: "4. Data Modeling in Power BI",
    detail1: "Importance of creating an efficient data model.",
    detail2:
      "Relationships: One-to-many, many-to-one, and many-to-many.",
    detail3: "Cardinality and Relationship Types.",
    detail4:
      "Using the Relationships View to build and manage data models.",
    detail5:
      "Creating Hierarchies.",
  },
  {
    _id: "4",
    title: "4. Data Visualization in Power BI",
    detail1: "Types of visualizations: Bar, Line, Pie, Scatter, Maps, and more.",
    detail2:
      "Using the Fields pane to drag-and-drop data into visuals.",
    detail3: "Configuring visual properties and formatting.",
    detail4:
      "Drill-through and Cross-Filtering between visuals.",
    detail5:
      "Designing responsive and dynamic reports.",
  },
  {
    _id: "5",
    title: "5. Advanced Data Transformations.",
    detail1: "Using DAX for advanced calculations (calculated columns and measures)",
    detail2:
      "Unpivoting and Pivoting data",
    detail3: "Merging and Appending Queries",
    detail4:
      "Conditional Columns and Custom Columns",
    detail5:
      "Grouping and Aggregating Data",
  },
  {
    _id: "6",
    title: "6. Introduction to DAX",
    detail1: "Basic DAX Syntax.",
    detail2:
      "Using Operators, Functions, and Constants in DAX.",
    detail3: "Date & Time Functions: DateAdd, DATEDIFF, etc.",
    detail4:
      "Mathematical and Statistical Functions.",
    detail5:
      "Filter Functions: CALCULATE, FILTER.",
  },
  {
    _id: "7",
    title: "7. Power BI Reports & Dashboards",
    detail1: "Choosing the right visuals for your data.",
    detail2:
      "Report layout, alignment, and spacing.",
    detail3: "Best practices for dashboard design.",
    detail4:
      "Incorporating slicers, drilldowns, and filters.",
    detail5:
      "Storytelling with Power BI: How to communicate insights visually.",
  },
  {
    _id: "8",
    title: "8. Power BI Service",
    detail1: "Introduction to the cloud-based Power BI Service.",
    detail2:
      "Key differences between Power BI Desktop and Power BI Service",
    detail3: "Uploading and publishing reports to the Power BI Service",
    detail4:
      "Creating dashboards from reports.",
    detail5:
      "Sharing and collaborating with stakeholders.",
  },
 
];

export default powerbi;
