import { Row, Col } from "react-bootstrap";
import Product from "../components/Product";
import python from "../python";

const PythonScreen = () => {
  return (
    <>
      <h1>Python Training Course Content</h1>
      <Row>
        <img
          src="images/Python.png"
          alt=""
          className="img-fluid roundedCircle width=50%"
        />
      </Row>

      <Row>
        {python.map((py) => (
          <Col key={py._id} sm={12} md={6} lg={3} xl={3}>
            <Product product={py} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PythonScreen;
