const quantitativeAptitude = [
  {
    _id: "1",
    title: "1. Numbers & Ages",
    detail1: "Types of Numbers: Natural numbers, whole numbers, integers, rational numbers, irrational numbers.",
    detail2: "Number Properties: Divisibility rules, prime numbers.",
    detail3: "Basic Age Problems: Linear and ratio-based age problems.",
    detail4:
      "Relation of Ages: Formulating equations from given age relations.",
    detail5:
      "Calculating Age Differences: Age gap, percentage-based problems.",
  },
  {
    _id: "2",
    title: "2. Allegation and Mixture & Average",
    detail1: "Concepts: Alligation, Mixture problems.",
    detail2: "Alligation Rule: Finding the ratio of mixing two or more components.",
    detail3: "Mixture of Different Quantities: Problem-solving with different percentage concentrations.",
    detail4: "Simple Average: Mean, median, mode.",
    detail5: "Problems on Averages: Solving average-related word problems.",
  },
  {
    _id: "3",
    title: "3. Partnership & Probability",
    detail1: "Partnership Basics: Profit-sharing, capital contributions.",
    detail2:
      "Profit/Loss Distribution: Based on investment and time period.",
    detail3: "Ratio of Investment: Simple and compound partnership problems.",
    detail4:
      "Basic Probability Theory: Understanding events, sample space, outcomes.",
    detail5:
      "Conditional Probability: Conditional events and their effects.",
  },
  {
    _id: "4",
    title: "4. Permutations and Combinations",
    detail1: "Permutations: Arrangements of objects, factorial notation.",
    detail2:
      "Combinations: Selection of objects without considering order.",
    detail3: "Formulae: Deriving and applying permutation and combination formulas.",
    detail4:
      "Difference Between Permutation and Combination: Order of arrangement, usage.",
    detail5:
      "Word Problems: Solving real-life Permutation and Combination scenarios.",
  },
  {
    _id: "5",
    title: "5. Profit and Loss",
    detail1: "Basic Concepts: Cost price, selling price, profit, and loss.",
    detail2:
      "Profit and Loss Tricks: Profit, Loss, P%, L%, SP, CP, Discount, MP, successive profits.",
    detail3: "Percentage-based Problems: Calculating profit/loss as a percentage.",
    detail4:
      "Discounts and Marked Price: Problems involving discounts, successive discounts, and profit percentage.",
    detail5:
      "Word Problems: Solving real-life profit and loss scenarios.",
  },
  {
    _id: "6",
    title: "6. Pipes and Cisterns",
    detail1: "Pipes and Cistern Concepts: inlet, outlet, leak, filling a tank and emptying a tank.",
    detail2:
      "Tips and Tricks: inlet, outlet, leak, filling a tank and emptying a tank.",
    detail3: "Working Together/Individually: When two or more pipes are filling or emptying a cistern.",
    detail4:
      "Time and Work Relationship: Solving problems on time taken to fill or empty a tank.",
    detail5:
      "Speed and Efficiency: Formulating and solving equations based on work and time.",
  },
  {
    _id: "7",
    title: "7. Percentage",
    detail1: "Concept of Percentage: Introduction, Percentage Definition, and Percentage in Math.",
    detail2:
      "Conversion: Converting between fractions, decimals, and percentages.",
    detail3: "Basic Percentage Trick: Percentage Formula, Percentage Formula in Case of a Fraction, Percentage Change Formula.",
    detail4:
      "Percentage Increase/Decrease: Solving problems on profit, loss, and price changes.",
    detail5:
      "Applications: Interest calculations, discounts, and commission problems.",
  },
  {
    _id: "8",
    title: "8. Simple Interest (SI) and Compound Interest (CI)",
    detail1: "Simple Interest: Formula, applications, solving for principal, rate, time.",
    detail2:
      "Compound Interest: Compound interest formulae, yearly and half-yearly compounding.",
    detail3: "Difference between SI and CI: When to use which formula.",
    detail4:
      "Tricks on Compound Interest And Simple Interest: Daily, Weekly, monthly Interest, time period of  2 years, 3 years.",
    detail5:
      "Problems: Solving problems on investment, loans, and deposits.",
  },
  {
    _id: "9",
    title: "9. Ratio and Proportion",
    detail1: "Definition and Types: Ratio, proportion, direct and inverse proportion.",
    detail2:
      "Tips and Tricks on Ratio and Proportion: Componendo rule, Dividendo rule,  Componendo & Dividendo rule.",
    detail3: "Solving Proportions: Solving problems involving parts and whole, and their relationships.",
    detail4:
      "Applications: Mixture problems, speed-distance-time, work-related problems.",
    detail5:
      "Ratio and Proportion Hints: Representation of Proportions, Multiply or divide ,Three quantities in continued proportion, Four quantities in continued proportion.",
  },
  {
    _id: "10",
    title: "10. Time and Work",
    detail1: "Introduction and Concept: Work Done, Rate of Work, Time Taken.",
    detail2: "Basic Work Problems: Single worker, multiple workers.",
    detail3:
      "Efficiency-based Problems: Time taken by workers working at different rates.",
    detail4:
      "Work and Wages: Relationship between work and wages in various scenarios.",
    detail5:
      "Inverse Proportions: Solving problems where time and work are inversely related.",
  },
  {
    _id: "11",
    title: "11. Area and Volume & Height and Distance",
    detail1: "2D Geometry: Area of squares, rectangles, circles, triangles, trapezium.",
    detail2: "3D Geometry: Volume and surface area of cubes, cuboids, spheres, cylinders, and cones.",
    detail3:
      "Applications: Real-life problems related to area and volume, land measurement, etc.",
    detail4:
      "Trigonometry Basics: Understanding angles, heights, and distances.",
    detail5:
      "Real-Life Applications: Solving problems involving angles of elevation and depression.",
  },
  {
    _id: "12",
    title: "12. Time and Distance",
    detail1: "Basic Concepts: Speed, time, distance, and their relationship.",
    detail2: "Relative Speed: Speed in opposite or same direction.",
    detail3:
      "Train Problems: Train length, crossing time, relative velocity.",
    detail4:
      "Speed, Distance, and Time Formulas: Solving problems based on specific types of questions.",
    detail5:
      "Boats and Streams Problems: Speed in Still Water, Current Effect, Relative Motion",
  },
];

export default quantitativeAptitude;
