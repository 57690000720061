import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../components/UI/card.css";

const Product = ({ product }) => {
  return (
    <>
    <div className="wrapper"></div>
    <Card className="card my-3 p-3 rounded">
      <Card.Body>
        <Card.Text as="h5">{product.title}</Card.Text>
        <Card.Text as="h6">{product.detail1}</Card.Text>
        <Card.Text as="h6">{product.detail2}</Card.Text>
        <Card.Text as="h6">{product.detail3}</Card.Text>
        <Card.Text as="h6">{product.detail4}</Card.Text>
        <Card.Text as="h6">{product.detail5}</Card.Text>
      </Card.Body>
    </Card>
    </>
  );
};

export default Product;
