const oops = [
  {
    _id: '1',
    title: '1. Introduction to Object-Oriented Programming (OOP).',
    detail1: 'Definition and History of OOP.',
    detail2: 'Key Principles of OOP: Encapsulation, Inheritance, Polymorphism, and Abstraction.',
    detail3: 'Why OOP is Essential in Modern Software Development.',
    detail4: 'Benefits of OOP: Code Reusability, Modularity, Maintainability.',
    detail5: 'Memory Management in OOP (Heap and Stack)',
    
  },
  {
    _id: '2',
    title: '2. Classes and Objects',
    detail1: 'Syntax for Defining a Class in Java.',
    detail2: 'Constructors: Default and Parameterized Constructors.',
    detail3: 'Instance Variables: Memory Allocation and Scope',
    detail4: 'Method Overloading and Constructor Overloading.',
    detail5: 'Access Control in Classes and Methods.',
    
    
  },
  {
    _id: '3',
    title: '3. Encapsulation',
    detail1: 'Definition and Purpose of Encapsulation.',
    detail2: 'Making Fields Private and Providing Access through Public Methods (Getter and Setter).',
    detail3: 'Preventing Direct Access to Data Fields.',
    detail4: 'Encapsulation vs Abstraction.',
    detail5: 'Building a Bank Account Class with Private Data and Public Methods.',
    
    
  },
  {
    _id: '4',
    title: '4. Inheritance',
    detail1: 'Definition and Types of Inheritance: Single, Multilevel, and Hierarchical Inheritance.',
    detail2: 'Deriving Subclasses from Superclasses.',
    detail3: 'Overriding Methods.',
    detail4: 'The `super` Keyword for Accessing Superclass Methods and Variables.',
    detail5: 'Difference Between Abstract Classes and Concrete Classes.',
    
    
  }, 
  {
    _id: '5',
    title: '5. Polymorphism',
    detail1: 'Definition of Polymorphism: One Interface, Many Implementations.',
    detail2: 'Compile-time (Method Overloading) vs Runtime (Method Overriding) Polymorphism.',
    detail3: 'Defining Multiple Methods with Same Name but Different Parameters.',
    detail4: 'Benefits and Use Cases of Method Overloading.',
    detail5: 'Runtime Polymorphism: Changing Behavior in Subclasses.',
    
    
  }, 
  {
    _id: '6',
    title: '6. Abstraction',
    detail1: 'Defining Abstraction in Java.',
    detail2: 'Hiding Implementation Details and Exposing Only Necessary Information.',
    detail3: 'Difference Between Abstract Classes and Interfaces.',
    detail4: 'Multiple Inheritance Using Interfaces (Java’s Solution to Multiple Inheritance Problem).',
    detail5: 'Building a Vehicle Interface and Implementing Different Vehicle Types (Car, Bike, Truck).',
    
    
  },
  {
    _id: '7',
    title: '7. Static Members and Class Design',
    detail1: 'Static Keyword: Shared Between All Instances of a Class.',
    detail2: 'Static Methods: Accessing Static Members without Creating Objects.',
    detail3: 'Static Blocks: For Static Initialization.',
    detail4: 'When to Use Static vs Non-static Members in Class Design.',
    detail5: 'Memory Allocation for Static and Instance Members.',
    
    
  },
  {
    _id: '8',
    title: '8. Exception Handling in OOP',
    detail1: 'Understanding Errors and Exceptions.',
    detail2: 'Types of Exceptions: Checked vs Unchecked.',
    detail3: 'Catching Exceptions and Ensuring Resource Cleanup.',
    detail4: 'Creating Custom Exception Classes.',
    detail5: 'Declaring Exceptions Using the `throws` Keyword.',
    
    
  },
]

export default oops
