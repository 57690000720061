import { Row, Col } from 'react-bootstrap';
import Product from '../components/Product';
import quantitativeAptitude from '../quantitativeAptitude';

const QAScreen = () => {
  return (
    <>
      <h1>Quantitative Aptitude Training Course Content</h1>
      <Row>
        <img
          src="images/qa.jpeg"
          alt=""
          className="img-fluid roundedCircle width=50%"
        />
      </Row>
      <Row>
        {quantitativeAptitude.map((py) => (
          <Col key={py._id} sm={12} md={6} lg={3} xl={3}>
            <Product product={py} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default QAScreen;
