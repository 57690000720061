const java = [
  {
    _id: '1',
    title: '1. Introduction to Java.',
    detail1: 'Overview of Java.',
    detail2: ' Features of Java (Platform independence, Object-Oriented, Robust, Secure).',
    detail3: 'Java Syntax and Structure.',
    detail4: 'Structure of a Java Program (Classes, Methods, Statements).',
    detail5: 'Comments in Java (Single-line, Multi-line, Javadoc comments).',
    
    
  },
  {
    _id: '2',
    title: '2. Data Types and Variables',
    detail1: 'Primitive Data Types (int, float, double, char, boolean).',
    detail2: 'Non-primitive Data Types (String, Arrays, Classes).',
    detail3: 'Variable Declaration and Initialization.',
    detail4: 'Type Casting (Implicit and Explicit).',
    detail5: 'Comments in Java (Single-line, Multi-line, Javadoc comments).',
    
    
  },
  {
    _id: '3',
    title: '3. Operators in Java',
    detail1: 'Arithmetic Operators.',
    detail2: 'Relational Operators.',
    detail3: 'Logical Operators.',
    detail4: 'Assignment Operators.',
    detail5: 'Unary and Bitwise Operators.',
    
    
  },
  {
    _id: '4',
    title: '4. Control Flow Statements',
    detail1: 'if, if-else, nested if-else.',
    detail2: 'switch-case statements.',
    detail3: 'for loop, while loop, do-while loop.',
    detail4: 'Nested loops.',
    detail5: 'Break and continue statements.',
    
    
  }, 
  {
    _id: '5',
    title: '5. Arrays and Strings',
    detail1: 'Declaring and Initializing Arrays.',
    detail2: 'One-dimensional Arrays.',
    detail3: 'Multi-dimensional Arrays (2D arrays).',
    detail4: 'Array Methods (length, sort, etc.).',
    detail5: 'String Operations (concatenation, comparison, substring, length, etc.).',
    
    
  }, 
  {
    _id: '6',
    title: '6. Functions and Methods',
    detail1: 'Method Declaration and Definition.',
    detail2: 'Method Parameters and Return Types.',
    detail3: 'Method Overloading (same method name, different parameters).',
    detail4: 'Passing variable-length arguments to a method.',
    detail5: 'Concept of Recursion.',
    
    
  },
  {
    _id: '7',
    title: '7. File Handling in Java',
    detail1: 'File Class (Creating, Deleting, Renaming Files).',
    detail2: 'Reading Files (FileReader, BufferedReader).',
    detail3: 'Writing to Files (FileWriter, BufferedWriter).',
    detail4: 'Writing and Reading Objects to/from Files.',
    detail5: 'ObjectOutputStream and ObjectInputStream.',
    
    
  },
  {
    _id: '8',
    title: '8. Introduction to Multithreading',
    detail1: 'What is a Thread? Why is Multithreading important?.',
    detail2: 'Creating a Thread (Extending Thread class vs Implementing Runnable interface).',
    detail3: 'Thread Lifecycle (New, Runnable, Waiting, Timed Waiting, Terminated).',
    detail4: 'Synchronizing Threads (synchronized keyword).',
    detail5: 'Preventing Deadlocks.',
    
    
  },
  {
    _id: '9',
    title: '9. Debugging and Best Practices',
    detail1: 'Debugging in Java.',
    detail2: 'Common Errors in Java (Syntax, Runtime, Logic errors).',
    detail3: 'Code Readability (Naming conventions, Comments, Indentation).',
    detail4: 'Handling Exceptions Gracefully.',
    detail5: 'Writing Efficient and Optimized Code.',
    
    
  },
]

export default java
