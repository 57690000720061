import { Row, Col } from 'react-bootstrap';
import Product from '../components/Product';
import powerbi from '../powerbi';

const PowerbiScreen = () => {
  return (
    <>
      <h1>Power BI Training Course Content</h1>
      <Row>
        <img
          src="images/powerbi.jpeg"
          alt=""
          className="img-fluid roundedCircle width=50%"
        />
      </Row>
      <Row>
        {powerbi.map((py) => (
          <Col key={py._id} sm={12} md={6} lg={3} xl={3}>
            <Product product={py} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PowerbiScreen;
