const dsa = [
  {
    _id: "1",
    title: "1. Arrays and Strings",
    detail1: "Definition, Declaration, and Initialization of Arrays",
    detail2: "Multi-dimensional Arrays (2D, 3D arrays)",
    detail3:
      "Operations on Arrays: Insertion, Deletion, Searching, Sorting, and Traversal",
    detail4:
      "String Representation and Manipulation (concatenation, substring, reversing)",
    detail5:
      "String Methods: `equals()`, `substring()`, `indexOf()`, `replace()`, etc.",
  },
  {
    _id: "2",
    title: "2. Linked Lists & Doubly Linked List",
    detail1: "Definition and Structure of a Singly Linked List",
    detail2: "Operations: Insertion, Deletion, Traversal, and Searching",
    detail3: "Handling Edge Cases: Empty list, Single-element list",
    detail4: "Structure of Doubly Linked List and Traversing Both Directions",
    detail5: "Operations: Insertion, Deletion, Searching",
  },
  {
    _id: "3",
    title: "3. Stacks,Queues, Binary Search Tree and Hash Table",
    detail1: "Definition and Operations: Push, Pop, Peek, and IsEmpty",
    detail2:
      "Definition and Operations: Enqueue, Dequeue, Front, Rear, IsEmpty",
    detail3: "Properties of Binary Trees and Full Binary Trees",
    detail4:
      "Structure of Binary Tree: Root, Left and Right Children, Leaf Nodes",
    detail5:
      "Defination of Hash Tables, Internal Working, Collison, Hash Functions and Operations",
  },
];

export default dsa;
