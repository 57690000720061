import { Row, Col } from 'react-bootstrap';
import Product from '../components/Product';
import java from '../java';
import oops from '../oops';
import dsa from '../dsa';

const JavaScreen = () => {
  return (
    <>
      <h1>Java Training Course Content</h1>
      <Row>
        <img
          src="images/java.png"
          alt=""
          className="img-fluid roundedCircle width=50%"
        />
      </Row>
      <h5>Java Basics</h5>
      <Row>
        {java.map((py) => (
          <Col key={py._id} sm={12} md={6} lg={3} xl={3}>
            <Product product={py} />
          </Col>
        ))}
      </Row>
      <h5>Java OOPs Concept</h5>
      <Row>
        {oops.map((py) => (
          <Col key={py._id} sm={12} md={6} lg={3} xl={3}>
            <Product product={py} />
          </Col>
        ))}
      </Row>
      <h5>Java Data Structure and Algorithm</h5>
      <Row>
        {dsa.map((py) => (
          <Col key={py._id} sm={12} md={6} lg={3} xl={3}>
            <Product product={py} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default JavaScreen;
