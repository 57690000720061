import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Container>
        <Row>
        <Col className='text-center py-3'>
        <h5 className=" text-dark">ctsevuga@gmail.com </h5>
          </Col>
          
          <Col className='text-center py-3'>
            <h5>9789911533</h5>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
