import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import "../components/UI/styles.css";

import { FaHandPointRight } from "react-icons/fa";

const HomeScreen = () => {
  const navigate = useNavigate();
  const pythonHandler = () => {
    navigate("/python");
  };
  const sqlHandler = () => {
    navigate("/sql");
  };

  const biHandler = () => {
    navigate("/powerbi");
  };
  const javaHandler = () => {
    navigate("/java");
  };
  const qaHandler = () => {
    navigate("/qa");
  };
  return (
    <>
      <header className="header">
        <div className="hero text-white pt-7">
          <div className="container-xxl margin-bottom: -500px">
            <div className="row">
              <div className="col-md-6">
                <div className="image-container mb-5 px-4">
                  <img
                    src="images/header-ebook.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-container p-4 d-flex flex-column justify-content-center h-100 mb-5">
                  <h5 className="display-5 fw-bold">
                    Welcome to Infowisdom Software Training
                  </h5>

                  <div className="form-container text-center">
                    <form>
                      <div className="d-grid">
                        <Button
                          className="btn-danger mx-2 mb-2"
                          type="button"
                          onClick={() => {
                            pythonHandler();
                          }}
                        >
                          Python
                        </Button>
                      </div>
                      <div className="d-grid">
                        <Button
                          className="btn-danger mx-2  mb-2"
                          type="button"
                          onClick={() => {
                            sqlHandler();
                          }}
                        >
                          RDBMS - SQL
                        </Button>
                      </div>
                      <div className="d-grid">
                        <Button
                          className="btn-danger mx-2  mb-2"
                          type="button"
                          onClick={() => {
                            biHandler();
                          }}
                        >
                          Power BI
                        </Button>
                      </div>
                      <div className="d-grid">
                        <Button
                          className="btn-danger mx-2  mb-2"
                          type="button"
                          onClick={() => {
                            javaHandler();
                          }}
                        >
                          Java
                        </Button>
                      </div>
                      <div className="d-grid">
                        <Button
                          className="btn-danger mx-2  mb-2"
                          type="button"
                          onClick={() => {
                            qaHandler();
                          }}
                        >
                          Quantitative Aptitude
                        </Button>
                      </div>
                    </form>
                    <p className="lead">
                    An application is developed with questions and answers from various topics such as Ages, Averages, Trains etc.. of Quantitative Aptitude.
                    </p>
                    <a 
        href="https://infowisdomqa.in" 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ 
          textDecoration: 'none', 
          color: 'white', 
          fontSize: '28px', 
          fontWeight: 'bold' 
        }}
      >
        Quantitative Aptitude Practice Application
      </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <svg
          className="frame-decoration"
          data-name="Layer 2"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          viewBox="0 0 1500 152.275"
          fill="#1877F2"
        >
          <title>frame-decoration</title>
          <path
            className="cls-1"
            d="M0,158.755s63.9,52.163,179.472,50.736c121.494-1.5,185.839-49.738,305.984-49.733,109.21,0,181.491,51.733,300.537,50.233,123.941-1.562,225.214-50.126,390.43-50.374,123.821-.185,353.982,58.374,458.976,56.373,217.907-4.153,284.6-57.236,284.6-57.236V351.03H0V158.755Z"
            transform="translate(0 -158.755)"
          />
        </svg>
      </header>
      <div className="container-xxl bg-primary text-white margin-bottom: -500px">
        <div className="row">
          <div className="col-md-6">
            <p className="lead">
              Unlock your potential with our expert-led courses in Quantitative
              Aptitude, Java, Python, RDBMS, SQL, and Power BI.
            </p>
            <h2 className="text-white">Why Choose Us?</h2>
            <ul className="list-group list-group-flush lh-lg">
              <li className="list-group-item">
                <FaHandPointRight size={28} className="text-success me-2" />
                <strong>Industry-Relevant Curriculum:</strong> Stay ahead with
                up-to-date training in high-demand technologies.
              </li>
              <li className="list-group-item">
                <FaHandPointRight size={28} className="text-success me-2" />
                <strong>Experienced Trainers:</strong> Learn from professionals
                with years of industry expertise.
              </li>
              <li className="list-group-item">
                <FaHandPointRight size={28} className="text-success me-2" />
                <strong>Flexible Learning Options:</strong> Online and offline
                classNamees tailored to fit your schedule.
              </li>
              <li className="list-group-item">
                <FaHandPointRight size={28} className="text-success me-2" />
                <strong>Practical Approach:</strong> Gain practical experience
                through projects, case studies, and assignments.
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <p className="lead">
              We offer hands-on training and real-world solutions to accelerate
              your career.
            </p>
            <h2 className="text-white">Courses We Offer</h2>
            <ul className="list-group list-group-flush lh-lg">
              <li className="list-group-item">
                {/* <i className="Fa FaFeather text-primary"></i> */}
                <FaHandPointRight size={28} className="text-warning me-2" />
                <strong>Quantitative Aptitude:</strong> Master the foundation of
                problem-solving for competitive exams and real-world
                applications.
              </li>
              <li className="list-group-item">
                <FaHandPointRight size={28} className="text-warning me-2" />
                <strong>Java & Python:</strong> Build a solid programming
                foundation for web development, data analysis, and beyond.
              </li>
              <li className="list-group-item">
                <FaHandPointRight size={28} className="text-warning me-2" />
                <strong>RDBMS & SQL:</strong> Understand the backbone of modern
                databases and learn to manage data efficiently.
              </li>
              <li className="list-group-item">
                <FaHandPointRight size={28} className="text-warning me-2" />
                <strong>Power BI:</strong> Transform raw data into actionable
                insights with powerful reporting and visualization skills.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
