import { Row, Col } from 'react-bootstrap';
import Product from '../components/Product';
import sql from '../sql';

const SQLScreen = () => {
  return (
    <>
      <h1>RDBMS & SQL Training Course Content</h1>
      <Row>
        <img
          src="images/sql.png"
          alt=""
          className="img-fluid roundedCircle width=50%"
        />
      </Row>
      <Row>
        {sql.map((py) => (
          <Col key={py._id} sm={12} md={6} lg={3} xl={3}>
            <Product product={py} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default SQLScreen;
