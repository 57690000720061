import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import PythonScreen from './screens/PythonScreen';
import JavaScreen from './screens/JavaScreen';
import QAScreen from './screens/QAScreen';
import SQLScreen from './screens/SQLScreen';
import PowerbiScreen from './screens/PowerbiScreen';
import ProductScreen from './screens/ProductScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route path='/python/' element={<PythonScreen />} />
      <Route path='/java/' element={<JavaScreen />} />
      <Route path='/qa/' element={<QAScreen />} />
      <Route path='/powerbi/' element={<PowerbiScreen />} />
      <Route path='/sql/' element={<SQLScreen />} />
      <Route path='/product/:id' element={<ProductScreen />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
