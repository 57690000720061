const python = [
  {
    _id: "1",
    title: "1. Introduction to Python",
    detail1: "What is Python?",
    detail2: "Overview of Python",
    detail3: "History and Evolution",
    detail4:
      "Python's Features and Advantages",
    detail5:
      "Applications of Python",
  },
  {
    _id: "2",
    title: "2. Python Programming Basics",
    detail1: "Understanding Python syntax",
    detail2: "Python keywords, identifiers, and comments",
    detail3: "Numbers, Strings, Lists, Tuples, Dictionaries, Sets",
    detail4: "Type Conversion",
    detail5: "Variables and Constants",
  },
  {
    _id: "3",
    title: "3. Conditional Statements",
    detail1: "if, elif, else statements",
    detail2:
      "Nested Conditions",
    detail3: "for loop",
    detail4:
      "while loop",
    detail5:
      "break, continue, and pass",
  },
  {
    _id: "4",
    title: "4. Python In-Built Data Structures",
    detail1: "List Operations: Append, Insert, Pop, Remove, Sort",
    detail2:
      "List slicing and indexing",
    detail3: "Tuple properties and operations",
    detail4:
      "Dictionary operations (add, update, delete)",
    detail5:
      "Set operations and properties",
  },
  {
    _id: "5",
    title: "5. Functions",
    detail1: "Defining Functions",
    detail2:
      "Function syntax and definition",
    detail3: "Function arguments and return values",
    detail4:
      "Default, keyword, and variable-length arguments",
    detail5:
      "Anonymous functions",
  },
  {
    _id: "6",
    title: "6. Searching and Sorting Algorithms",
    detail1: "Linear Search",
    detail2:
      "Binary Search",
    detail3: "Bubble Sort",
    detail4:
      "Selection Sort",
    detail5:
      "Insertion Sort",
  },
  {
    _id: "7",
    title: "7. File Handling",
    detail1: "Reading and Writing Files",
    detail2:
      "Opening, closing, reading, and writing files",
    detail3: "Working with text files",
    detail4:
      "File Operations",
    detail5:
      "Working with CSV, and JSON Files",
  },
  {
    _id: "8",
    title: "8. Exception Handling",
    detail1: "Syntax errors vs runtime errors",
    detail2:
      "Types of exceptions in Python",
    detail3: "Using try-except blocks",
    detail4:
      "Handling multiple exceptions",
    detail5:
      "Custom exceptions",
  },
  {
    _id: "9",
    title: "9. Object-Oriented Programming (OOPs) Concepts",
    detail1: "What is OOP and its principles? - Classes and Objects",
    detail2:
      "Encapsulation - Private and Public members - Getter and Setter methods",
    detail3: "Inheritance - Inheriting attributes and methods - Method overriding",
    detail4:
      "Polymorphism - Static polymorphism (method overloading) - Dynamic polymorphism (method overriding)",
    detail5:
      "Abstract classes and methods",
  },
  {
    _id: "10",
    title: "10. Data Structures",
    detail1: "Singly Linked Lists",
    detail2: "Doubly Linked Lists",
    detail3:
      "Stacks and Queues",
    detail4:
      "Binary Search Trees",
    detail5:
      "Hash Tables",
  },
  {
    _id: "11",
    title: "11. Data Analysis",
    detail1: "Overview of Pandas and Numpy libraries",
    detail2: "Working with DataFrames",
    detail3:
      "Data cleaning, transformation, and manipulation",
    detail4:
      "Grouping and Aggregation",
    detail5:
      "Join, Merge, and Concatenate in Pandas",
  },
  {
    _id: "12",
    title: "12. Data Visualization",
    detail1: "Introduction to Data Visualization",
    detail2: "Importance of data visualization in analysis",
    detail3:
      "Matplotlib - Creating line charts, bar charts, histograms, and scatter plots",
    detail4:
      "Seaborn - Statistical plots: boxplot, heatmaps, violin plots",
    detail5:
      "Plotly - Interactive Visualizations",
  },
];

export default python;
